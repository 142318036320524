import "./App.css";
import { Routes, Route } from "react-router-dom";
import routes from "./routes";
import PrivateContainer from "./routes/PrivateContainer";
import NotFound from "./pages/NotFound";
import { ToastBar, Toaster } from "react-hot-toast";
import PublicContainer from "./routes/PublicContainer";

function App() {
  return (
    <>
      <Routes>
        {routes.map(({ path, Component, secure, role }, i) => {
          if (secure) {
            return (
              <Route
                key={i}
                path={path}
                element={
                  <PrivateContainer roles={role} path={path}>
                    <Component />
                  </PrivateContainer>
                }
              />
            );
          } else {
            return <Route key={i} path={path} element={<PublicContainer><Component /></PublicContainer>} />;
          }
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster
        position="top-center"
        toastOptions={{ duration: 5000, className: "toast-alert" }}
      >
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
              animation: t.visible
                ? "custom-enter 1s ease"
                : "custom-exit 1s ease",
            }}
          />
        )}
      </Toaster>
    </>
  );
}

export default App;
