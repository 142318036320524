import { useEffect } from "react";
import { useNavigate } from "react-router";
import { checkLogin } from "../api/auth";
import { assignQRToUser } from "../api/qrcode";

const PrivateContainer = ({ children, roles, path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async () => {
    try {
      const { data } = await checkLogin();

      if (
        data.user &&
        data.user.provider === "google" &&
        data.user.role === "user" &&
        localStorage.getItem("link")
      ) {
        try {
          await assignQRToUser({
            link: localStorage.getItem("link"),
            user: data.user._id,
          });
          window.location.reload();
        } catch (error) {
          console.log("Error while assigning QR to user: ", error.message);
        }
        localStorage.removeItem("link");
        navigate("/");
      }
      if (roles && data?.user?.role && !roles.includes(data.user.role)) {
        navigate(data?.user?.role === "admin" ? "/admin" : "/");
      }
    } catch (error) {
      console.log("Error while checking login status: ", error);
      navigate("/login");
    }
  };

  return children;
};

export default PrivateContainer;
