import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getQRCodes } from "../api/qrcode";
import toast from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  adaptiveHeight: true,
  centerMode: false,
};

const BarcodeLists = () => {
  const { id } = useParams();

  const [processing, setProcessing] = useState(false);
  const [barcodeData, setBarcodeData] = useState(null);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  console.log("🚀 ~ BarcodeLists ~ selectedImage:", selectedImage);

  const getBarcodeData = async () => {
    setProcessing(true);
    try {
      const { data } = await getQRCodes(id);
      setBarcodeData(data.barcode);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error while getting barcode data"
      );
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    getBarcodeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="py-10 px-4 lg:py-16 lg:px-16 max-w-[1400px] w-full mx-auto space-y-8">
        <div className="max-w-7xl mx-auto">
          <div className="my-8 lg:my-16">
            <div className="flex flex-col">
              <div className="max-w-[800px] overflow-hidden w-full mx-auto bg-gray-100 rounded max-h-auto overflow-y-auto">
                {/* Title Section */}
                {barcodeData?.storedInfo?.memories?.title && (
                  <div className="text-center rounded-md">
                    <h1 className="text-2xl font-medium">
                      {barcodeData?.storedInfo?.memories?.title}
                    </h1>
                  </div>
                )}

                {/* Images Carousel */}
                {barcodeData?.storedInfo?.memories?.images?.length > 0 && (
                  <div className="space-y-4 mb-10">
                    <Slider {...settings}>
                      {barcodeData?.storedInfo?.memories?.images?.map(
                        (image, index) => (
                          <div key={index}>
                            <img
                              src={image}
                              alt={`Memory ${index + 1}`}
                              className="w-full h-auto object-cover rounded-md cursor-pointer"
                              onClick={() => setSelectedImage(image)}
                            />
                          </div>
                        )
                      )}
                    </Slider>
                  </div>
                )}

                {/* Image Pop-out */}
                {selectedImage && (
                  <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                    onClick={() => setSelectedImage(null)}
                  >
                    <div className="relative max-w-4xl w-full py-4">
                      <div className="relative">
                        <img
                          src={selectedImage}
                          alt="Enlarged view"
                          className="w-full h-auto max-h-[90vh] object-contain"
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedImage(null);
                          }}
                          className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full text-[27px] text-xl w-7 h-7 flex items-center justify-center leading-none hover:bg-opacity-75 transition-opacity"
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {barcodeData?.storedInfo?.memories?.imageText && (
                  <div className="text-center py-4 rounded-md">
                    <h1 className="text-[16px] break-words text-left">
                      {barcodeData?.storedInfo?.memories?.imageText}
                    </h1>
                  </div>
                )}

                {/* Free Text Section */}
                {barcodeData?.storedInfo?.memories?.freeText && (
                  <div className=" py-4 text-2xl text-center rounded-md">
                    <p className="break-words text-left text-[16px]">
                      {barcodeData.storedInfo.memories.freeText
                        .split(/(\s+)/)
                        .map((word, index) => {
                          const urlRegex = /^(https?:\/\/[^\s]+)$/;
                          if (urlRegex.test(word)) {
                            return (
                              <a
                                href={word}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 underline"
                              >
                                {word}
                              </a>
                            );
                          }
                          return word;
                        })}
                    </p>
                  </div>
                )}

                {barcodeData?.storedInfo?.memories?.videoUrl && (
                  <div className="space-y-4">
                    <div className="flex justify-center">
                      {/* Check if videoUrl contains 'youtube.com' or 'youtu.be' */}
                      {barcodeData?.storedInfo?.memories?.videoUrl.includes(
                        "youtube.com"
                      ) ||
                      barcodeData?.storedInfo?.memories?.videoUrl.includes(
                        "youtu.be"
                      ) ? (
                        <iframe
                          className="w-full rounded-md h-[300px] md:h-[400px]"
                          src={`https://www.youtube.com/embed/${
                            barcodeData?.storedInfo?.memories?.videoUrl.split(
                              "/"
                            )[3]
                          }`}
                          title={`YouTube video player for ${barcodeData?.storedInfo?.memories?.videoUrl}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      ) : (
                        <div className=" py-4 text-[16px] text-center rounded-md">
                          {barcodeData?.storedInfo?.memories?.videoUrl ||
                            "Free text"}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Video Section */}
                {barcodeData?.storedInfo?.memories?.videos?.length > 0 && (
                  <div className="space-y-4">
                    {barcodeData?.storedInfo?.memories?.videos?.map(
                      (video, index) => (
                        <div key={index} className="flex justify-center">
                          <video
                            className="w-full h-auto md:h-[400px] rounded-md"
                            controls
                            loop
                            playsInline
                            type={`video/${video.split(".").pop()}`}
                          >
                            <source src={`${video}#t=0.001`} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )
                    )}
                  </div>
                )}

                {barcodeData?.storedInfo?.memories?.videoDescription && (
                  <div className="text-center  py-4 rounded-md">
                    <h1 className="text-[16px] break-words text-left">
                      {barcodeData?.storedInfo?.memories?.videoDescription}
                    </h1>
                  </div>
                )}

                {barcodeData?.storedInfo?.memories?.pdfTitle && (
                  <div className="text-center  py-4 rounded-md">
                    <h1 className="text-2xl font-medium break-words text-[16px]">
                      {barcodeData?.storedInfo?.memories?.pdfTitle}
                    </h1>
                  </div>
                )}

                {/* PDF Section with Name and Button for each PDF */}
                {barcodeData?.storedInfo?.memories?.pdfs?.length > 0 && (
                  <div className="space-y-4 ">
                    {barcodeData?.storedInfo?.memories?.pdfs?.map(
                      (pdf, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center rounded-md"
                        >
                          <span className="text-[16px]">
                            {`${pdf.split("/").pop()}`}
                          </span>
                          <button
                            onClick={() => {
                              setPdfUrl(pdf);
                              setIsPdfOpen(true);
                            }}
                            className="bg-tealBlue text-white py-1 px-3 rounded-md"
                          >
                            Open
                          </button>
                        </div>
                      )
                    )}
                  </div>
                )}

                {isPdfOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="relative  w-full max-w-4xl py-4 rounded-lg">
                      {/* Close Icon */}
                      <button
                        onClick={() => setIsPdfOpen(false)}
                        className="absolute top-4 right-4 bg-black bg-opacity-50 text-white rounded-full text-[27px] text-xl w-6 h-6 flex items-center justify-center leading-none hover:bg-opacity-75 transition-opacity"
                      >
                        &times;
                      </button>

                      {/* PDF Iframe */}
                      <iframe
                        src={pdfUrl}
                        className="w-full h-[90vh] rounded-lg"
                        title="PDF Viewer"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Loading State */}
        {processing ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
            <div
              className="h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BarcodeLists;
