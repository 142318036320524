import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { IoLinkSharp } from "react-icons/io5";
import Tooltip from "./componets/Tooltip";
import QRUploadModal from "./componets/QRUploadModal";
import {
  approveQrCode,
  deleteQRCode,
  deleteQRCodeImage,
  disableQrCode,
  enableQrCode,
  generateLinks,
  listQrCodes,
} from "../api/qrcode";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { IoSearchOutline } from "react-icons/io5";
import { LiaEditSolid } from "react-icons/lia";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Header from "./header";
import { MdOutlineFileUpload } from "react-icons/md";
import apiClient from "../api";
import QREditModal from "./componets/QREditModal";
import ReactPaginate from "react-paginate";
import { formatDate } from "../utils/commonUtils";
import { getTexts, updateTexts } from "../api/auth";
import SmallLoader from "./componets/smallLoader";

const GenerateLinks = () => {
  const [processing, setProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const rowsLimit = 50;
  // const [rowsLimit, setRowsLimit] = useState(10);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalQR, setTotalQR] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchItems, setSearchItems] = useState({});
  const searchInputRef = useRef(null);
  const [linkAmount, setLinkAmount] = useState(null);
  const [selectedBarcode, setSelectedBarcode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cureentText, setCureentText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [qrId, setQrId] = useState("");

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  // manage advertisement
  useEffect(() => {
    textDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textDetails = async () => {
    try {
      const { data } = await getTexts();
      if (data?.bannerTexts) {
        setCureentText(data?.bannerTexts);
      }
    } catch (error) {
      console.log("Error while calling api ", error);
    }
  };

  const [topAddBannerText, setTopAddBannerText] = useState(
    cureentText[0]?.topBannerText
  );
  const [bottomAddBannerText, setBottomAddBannerText] = useState(
    cureentText[0]?.bottomBannerTextBannerText
  );
  const [sideBannerText, setSideBannerText] = useState(
    cureentText[0]?.sideBannerText
  );

  const handleTextUpdate = async () => {
    setProcessing(true);
    let payload = {
      topBannerText: topAddBannerText
        ? topAddBannerText
        : cureentText[0]?.topBannerText,
      bottomBannerText: bottomAddBannerText
        ? bottomAddBannerText
        : cureentText[0]?.bottomBannerText,
      sideBannerText: sideBannerText
        ? sideBannerText
        : cureentText[0]?.sideBannerText,
    };
    try {
      await updateTexts(payload);
      toast.success("text updated successfully");
    } catch (error) {
      toast.error("Error while updating text");
    } finally {
      setProcessing(false);
    }
  };

  // indeterminate checkboxes
  const [checkedState, setCheckedState] = useState({
    checkbox: 0,
    checkbox2: 0,
  });
  const handleCheckboxClick = (name) => {
    setCheckedState((prevState) => {
      const newState = { ...prevState };
      switch (newState[name]) {
        case 0:
          newState[name] = 1;
          break;
        case 1:
          newState[name] = 2;
          break;
        default:
          newState[name] = 0;
      }
      return newState;
    });
  };

  useEffect(() => {
    switch (checkedState.checkbox) {
      case 2:
        setSearchItems((prev) => ({
          ...prev,
          isActive: true,
        }));
        break;
      case 1:
        setSearchItems((prev) => ({
          ...prev,
          isActive: false,
        }));
        break;
      default:
        setSearchItems((prev) => {
          const data = { ...prev };
          delete data.isActive;
          return data;
        });
    }

    switch (checkedState.checkbox2) {
      case 2:
        setSearchItems((prev) => ({
          ...prev,
          approved: true,
        }));
        break;
      case 1:
        setSearchItems((prev) => ({
          ...prev,
          approved: false,
        }));
        break;
      default:
        setSearchItems((prev) => {
          const data = { ...prev };
          delete data.approved;
          return data;
        });
    }
  }, [checkedState]);

  const clearData = () => {
    searchInputRef.current.value = "";
    setSearchItems({});
  };

  const updateRecord = (row) => {
    const updatedRows = rowsToShow.map((item) => {
      if (item._id === row._id) {
        return row;
      }
      return item;
    });
    setRowsToShow(updatedRows);
  };

  const approveQR = async (id) => {
    try {
      console.log("id", id);
      const { data } = await approveQrCode(id);
      updateRecord(data.barcode);
      toast.success(data.message || "QR code approved successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error approving qr code");
    }
  };

  const enableDisableQrCode = async (id, isActive) => {
    try {
      const { data } = isActive
        ? await disableQrCode(id)
        : await enableQrCode(id);
      updateRecord(data.barcode);
      toast.success(data.message || "QR code updated successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error updating qr code");
    }
  };

  const getQRList = useCallback(
    async (limit, page, filter) => {
      try {
        const skip = limit * page;
        const response = await listQrCodes({ limit, skip, ...filter });
        const total = isNaN(response.data.total)
          ? 0
          : Math.ceil(response.data.total / limit);
        setTotalPage(total);
        setTotalQR(response.data.total);

        if (currentPage > total) {
          setCurrentPage(0);
        }

        setRowsToShow(response.data.barCodes);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching qr list:", error);
      }
    },
    [currentPage]
  );

  const refreshData = () => {
    getQRList(rowsLimit, currentPage, searchItems);
  };

  // react-paginate:
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // QR Upload:
  const [progress, setProgress] = useState(0);
  const onDrop = useCallback(async (files, unapprovedFile, event) => {
    try {
      const id =
        (event && event?.target?.parentNode?.firstChild?.id) ||
        localStorage.getItem("barcodeId");

      if (id && files.length > 0) {
        const formData = new FormData();
        formData.append("image", files[0]);
        const { data } = await apiClient().post(
          `/barcode/upload/${id}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setProgress((prevProgress) => prevProgress + percentage);
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        refreshData();
        toast.success(data?.message || "Image uploaded successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Internal server error");
      console.error("Error uploading files:", error);
    } finally {
      localStorage.removeItem("barcodeId");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop,
  });

  const onGenerateLinks = async (linkAmount) => {
    try {
      const { data } = await generateLinks({ amount: linkAmount });
      const csvData = data.barcodeCSV;
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `links-${new Date().toISOString()}.csv`);
      document.body.appendChild(link);
      link.click();
      toast.success(data.message || "QR code generated successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error generating qr code");
    } finally {
      getQRList(rowsLimit, currentPage, searchItems);
      setLinkAmount(null);
    }
  };

  useEffect(() => {
    getQRList(rowsLimit, currentPage, searchItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsLimit, searchItems]);

  const handleDeleteQrCode = async (id) => {
    try {
      const data = await deleteQRCode(id);
      if (data?.status !== 200) throw data?.data;
      refreshData();
      toast.success(data?.data.message || "QR code deleted successfully");
      return true;
    } catch (error) {
      toast.error(error.message || "Error deleting qr code");
    }
  };

  // delete barcode image
  const handleDeleteQrCodeImage = async (id) => {
    try {
      const data = await deleteQRCodeImage(id);
      if (data?.status !== 200) throw data?.data;
      refreshData();
      toast.success(data?.data.message || "QR code Image deleted successfully");
      return true;
    } catch (error) {
      toast.error(error.message || "Error deleting QR code Image");
    }
  };

  // reset filter function
  const handleResetFilter = () => {
    setSearchItems({});
    setCheckedState({
      checkbox: 0,
      checkbox2: 0,
    });
  };

  return (
    <>
      <Header />
      <div className="flex items-center justify-center mt-8 pb-10 px-10">
        <div className="w-full max-w-[1450px]">
          <div className="flex justify-between">
            <h1 className="sm:text-3xl tex-xl font-medium pb-2">
              Generate QR Links
            </h1>
            <h2 className="text-2xl font-medium">Total QR: {totalQR}</h2>
          </div>
          <div className="flex rounded-ss-lg rounded-se-lg border border-gray-400 border-b-transparent bg-white justify-between bg-[#222E3A]/[6%] px-4 mt-2 py-3">
            <div className="inline-block w-[25%]">
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="bg-tealBlue text-white py-2 px-4 mr-4 rounded border flex-col justify-center hover:bg-lightBlue hover:text-black border-borderMuted flex focus:outline-none transition-all duration-500"
              >
                Upload QRs
              </button>
              {showModal ? (
                <QRUploadModal
                  setShowModal={setShowModal}
                  refreshData={refreshData}
                />
              ) : null}
            </div>
            <div className="flex items-center justify-end w-[75%]">
              <div className="inline-block w-[30%]">
                <button
                  type="button"
                  onClick={handleResetFilter}
                  className="bg-tealBlue text-white py-2 px-4 mr-4 rounded border flex-col justify-center hover:bg-lightBlue hover:text-black border-borderMuted flex focus:outline-none transition-all duration-500"
                >
                  Reset
                </button>
              </div>
              <div className="flex items-center mr-3">
                <input
                  type="date"
                  className="border px-1 mr-1"
                  id="startDate"
                  onChange={(e) => {
                    setSearchItems((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }));
                  }}
                  max={searchItems?.endDate}
                />
                <p>To</p>
              </div>
              <div className="flex items-center mr-3">
                <input
                  type="date"
                  className="border px-1 mr-1"
                  id="endDate"
                  defaultValue={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setSearchItems((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }));
                  }}
                  min={searchItems?.startDate}
                />
              </div>
              <div className="flex items-center mr-3">
                <input
                  type="checkbox"
                  className="w-[18px] h-[18px] mr-1 cursor-pointer"
                  id="enableCheckBox"
                  checked={checkedState.checkbox === 2}
                  onChange={() => handleCheckboxClick("checkbox")}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = checkedState.checkbox === 1;
                    }
                  }}
                />
                <p>Enabled</p>
              </div>
              <div className="flex items-center mr-3">
                <input
                  type="checkbox"
                  className="w-[18px] h-[18px] mr-1 cursor-pointer"
                  id="approveCheckBox"
                  checked={checkedState.checkbox2 === 2}
                  onChange={() => handleCheckboxClick("checkbox2")}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = checkedState.checkbox2 === 1;
                    }
                  }}
                />
                <p>Approved</p>
              </div>
              <input
                type="number"
                placeholder="Enter amount"
                min={1}
                max={1000}
                value={linkAmount}
                onChange={(e) => setLinkAmount(e.target.value)}
                className="px-2 py-2.5 rounded-lg bg-bg-lightBlue border border-borderMuted h-[42px] focus:outline-none max-w-[140px] w-full"
              />
              <button
                onClick={(e) => {
                  if (!linkAmount || linkAmount < 1 || linkAmount > 1000) {
                    toast.error("Please enter a valid amount of link");
                  } else {
                    onGenerateLinks(linkAmount);
                  }
                }}
                className="bg-tealBlue text-white py-2 px-4 rounded mx-2 sm:mx-4 border  flex-col justify-center hover:bg-lightBlue hover:text-black border-borderMuted flex transition-all duration-500 focus:outline-none text-nowrap"
              >
                Generate Links
              </button>
              <div className="px-2 py-2.5 rounded-lg bg-bg-lightBlue border border-borderMuted h-[42px] max-w-[150px] w-full">
                <div className="flex items-center">
                  <IoSearchOutline />
                  {/* Search api when user hit enter and respective event on mobile  */}
                  <input
                    type="text"
                    ref={searchInputRef}
                    className="max-w-[170px] text-sm bg-transparent focus:ring-0 border-transparent outline-none placeholder:text-black text-black w-[85%] ml-1"
                    placeholder="Search links"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        setSearchItems((prev) => ({
                          ...prev,
                          link: e.target.value,
                        }));
                      }
                    }}
                  />
                  <IoCloseOutline
                    className={`text-xl text-black cursor-pointer ${
                      Object.keys(searchItems).length ? "visible" : "invisible"
                    }`}
                    onClick={clearData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white overflow-x-scroll md:overflow-auto max-w-[1450px] rounded-ee-lg rounded-es-lg border border-gray-400 relative min-h-[648px]">
            <div className="overflow-scroll md:overflow-auto w-full text-left font-inter border ">
              <div className="rounded-lg text-base text-white font-semibold w-full border-b-0"></div>
              <div className="flex flex-wrap p-5 justify-center">
                {rowsToShow?.map((data, index) => (
                  <div
                    className="bg-gray-100 max-w-[250px] w-full border border-gray-400 rounded-lg mx-3 my-3"
                    key={index}
                  >
                    <div
                      className={`py-4 px-4 font-normal text-base whitespace-nowrap w-full`}
                    >
                      {data?.imageLink ? (
                        <div className="w-[150px] mx-auto relative">
                          <img
                            src={data?.imageLink}
                            alt=""
                            className="w-full"
                          />
                          <button
                            onClick={(e) => handleDeleteQrCodeImage(data?._id)}
                            className="text-2xl font-medium w-[20px] h-[20px] rounded-full bg-white flex items-center justify-center absolute right-[-7%] top-[-6%]"
                          >
                            <IoCloseOutline />
                          </button>
                        </div>
                      ) : (
                        <div className="w-[150px] mx-auto h-[150px] rounded-lg border border-gray-400 flex items-center justify-center">
                          <div
                            {...getRootProps({
                              className: "dropzone",
                            })}
                            onClick={() => {
                              localStorage.setItem("barcodeId", data?._id);
                              setTimeout(() => {
                                open();
                              }, 1000);
                            }}
                          >
                            <input
                              {...getInputProps()}
                              id={data?._id}
                              name="image"
                            />
                            <div
                              color="secondary"
                              fontSize="large"
                              style={{ marginLeft: "35px" }}
                            />
                            <MdOutlineFileUpload className="text-4xl cursor-pointer" />
                          </div>
                        </div>
                      )}
                      <div className="mt-4">
                        <div className="py-2 px-2 my-2 flex items-center border border-gray-400 rounded-lg">
                          <p className="mr-2 text-lg inline-block mt-0.5">
                            <IoLinkSharp />
                          </p>
                          <Link
                            src="/"
                            className="hover:text-tealBlue text-lg inline-block leading-none"
                          >
                            {data?.link}
                          </Link>
                        </div>
                        <div className="py-2 text-base px-2">
                          {data?.imageUploadDate && (
                            <p>
                              <span>Uploaded Date:</span>{" "}
                              <span>
                                {formatDate(data?.imageUploadDate) || ""}
                              </span>
                            </p>
                          )}
                          {data?.approvedDate && (
                            <p>
                              <span>Approved Date:</span>{" "}
                              <span>
                                {formatDate(data?.approvedDate) || ""}
                              </span>
                            </p>
                          )}
                        </div>

                          <p className="text-left text-sm sm:text-base flex group relative cursor-pointer">
                            <b>QR Name:</b> <span className="text-ellipsis ps-1">{data?.name}</span> <span className="hidden group-hover:block bg-black py-1 px-2 rounded-md absolute top-[-115%] text-base text-white z-10">{data?.name}</span>
                          </p>

                        <div className="w-full pb-2">
                          {data?.user && (
                            <>
                              <p className="text-left text-sm sm:text-base">
                                <b>Name:</b> {data?.user?.firstName}{" "}
                                {data?.user?.lastName}
                              </p>
                              <p className="text-left text-sm sm:text-base flex group relative cursor-pointer">
                                <b>Email :</b>{" "}
                                <span className="text-ellipsis ps-1">
                                  {data?.user?.email}
                                </span>
                                <span className="hidden group-hover:block bg-black py-1 px-2 rounded-md absolute top-[-115%] text-base text-white z-10">
                                  {" "}
                                  {data?.user?.email}
                                </span>
                              </p>
                            </>
                          )}
                          {data?.scanCount ? (
                            <p className="text-left text-sm sm:text-base">
                              <b>Scan Count:</b> {data?.scanCount || 0}
                            </p>
                          ) : null}
                          {data?.storedInfo?.link ? (
                            <p className="text-sm sm:text-base flex w-full group relative">
                              <b className="text-black pe-1">
                                {data?.storedInfo?.infoType} :
                              </b>{" "}
                              {data?.storedInfo?.infoType === "phoneNumber" ? (
                                data?.storedInfo?.link
                              ) : (
                                <>
                                  <a
                                    href={data?.storedInfo?.link}
                                    className="text-black text-ellipsis flex-1"
                                  >
                                    {data?.storedInfo?.link}
                                  </a>
                                  <span className="hidden group-hover:block bg-black py-1 px-2 rounded-md absolute top-[-115%] text-base text-white z-10">
                                    {" "}
                                    {data?.storedInfo?.link}
                                  </span>
                                </>
                              )}
                            </p>
                          ) : null}
                        </div>
                        {data?.user ? (
                          <div className="flex justify-center mb-2 select-none items-center rounded-full bg-gray-900/10 py-1.5 px-3 font-sans text-xs font-bold uppercase text-gray-900">
                            <span>paying</span>
                          </div>
                        ) : data?.storedInfo ? (
                          <div className="flex justify-center mb-2 select-none items-center rounded-full bg-gray-900/10 py-1.5 px-3 font-sans text-xs font-bold uppercase text-gray-900">
                            <span>nonpaying</span>
                          </div>
                        ) : null}
                        <div className="flex items-center justify-evenly gap-2">
                          <Tooltip text="Approved/Disapproved">
                            <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer mt-1.5">
                              <input
                                id={"Approved" + index}
                                checked={data?.approved}
                                type="checkbox"
                                className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-red-600 checked:bg-green-600 peer-checked:border-green-600 peer-checked:before:bg-green-600"
                                onChange={(e) => {
                                  approveQR(data?._id);
                                }}
                              />
                              <label
                                htmlFor={"Approved" + index}
                                className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                              >
                                <div
                                  className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                                  data-ripple-dark="true"
                                ></div>
                              </label>
                            </div>
                          </Tooltip>
                          {/* <button
                            onClick={(e) => {
                              if (!data?.approved) {
                                approveQR(data?._id);
                              }
                            }}
                            disabled={data?.approved || !data?.imageLink}
                            className={`px-3 py-1 border border-gray-400 text-white rounded-lg text-sm disabled:opacity-70 ${
                              data?.approved ? "bg-green-600" : "bg-red-600"
                            } transition-all duration-500`}
                          >
                            {data?.approved ? "Approved" : "Approve"}
                          </button> */}
                          <Tooltip text="Enable/Disable">
                            <div className="inline-flex items-center">
                              <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer mt-1.5">
                                <input
                                  id={index}
                                  checked={data?.isActive}
                                  type="checkbox"
                                  className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-red-600 checked:bg-green-600 peer-checked:border-green-600  peer-checked:before:bg-green-600"
                                  onChange={(e) => {
                                    console.log("do you also");
                                    enableDisableQrCode(
                                      data?._id,
                                      data?.isActive
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={index}
                                  className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                                >
                                  <div
                                    className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                                    data-ripple-dark="true"
                                  ></div>
                                </label>
                              </div>
                            </div>
                          </Tooltip>
                          <button
                            className="inline-block mb-1 cursor-pointer"
                            onClick={(e) => {
                              setSelectedBarcode(data);
                              setUpdateModal(true);
                            }}
                          >
                            <LiaEditSolid className="text-2xl" />
                          </button>
                          <button
                            className="inline-block mb-1 cursor-pointer"
                            onClick={() => {
                              toggleModal();
                              setQrId(data?._id);
                            }}
                          >
                            <MdDeleteOutline className="text-2xl" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {isOpen && (
                  <div className="fixed top-0 h-screen w-screen bg-black bg-opacity-60 flex items-center justify-center">
                    <div className="bg-white max-w-xl rounded-md">
                      <div className="relative p-3 flex items-center justify-cente">
                        <div className="p-3">
                          <p>Are you sure you want to delete this QR code?</p>
                        </div>
                      </div>
                      <div className="p-3 flex justify-end gap-x-3">
                        <button
                          className="modal-close text-sm text-white bg-blue-500 hover:text-gray-400 hover:bg-white border rounded-md px-4 py-2"
                          onClick={() => {
                            handleDeleteQrCode(qrId);
                            toggleModal();
                            setQrId(null);
                          }}
                        >
                          Yes
                        </button>
                        <button
                          className="modal-close text-sm hover:text-white hover:bg-blue-500 text-gray-400 border rounded-md px-4 py-2"
                          onClick={() => {
                            toggleModal();
                            setQrId(null);
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* loader */}
            {isLoading ? (
              <div>
                <div
                  className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] absolute top-[50%] left-[50%]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
                <div className="bg-black opacity-40 absolute right-0 left-0 top-0 bottom-0"></div>
              </div>
            ) : null}
          </div>
          {/* table paginatin*/}
          <div className="flex justify-end table-pagination mt-5">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
      {updateModal ? (
        <QREditModal
          setShowModal={setUpdateModal}
          refreshData={refreshData}
          barCode={selectedBarcode}
        />
      ) : null}
      {/* Advertisement banner input*/}
      <div className="w-full max-w-[1450px] px-10 2xl:px-0 mx-auto m-8">
        <h3 className="text-3xl font-medium pb-3">Manage Advertisement</h3>
        <form>
          <div className="">
            <div className="mb-3">
              <h5 className="text-xl font-medium pb-2">Top Banner Text:</h5>
              <textarea
                cols="50"
                rows="5"
                className="outline-none p-3 border border-gray-400 rounded-lg"
                defaultValue={cureentText[0]?.topBannerText}
                onChange={(e) => setTopAddBannerText(e.target.value)}
                value={topAddBannerText}
              />
            </div>
            <div className="mb-3">
              <h5 className="text-xl font-medium pb-2">Bottom Banner Text:</h5>
              <textarea
                cols="50"
                rows="5"
                className="outline-none p-3 border border-gray-400 rounded-lg"
                defaultValue={cureentText[0]?.bottomBannerText}
                onChange={(e) => setBottomAddBannerText(e.target.value)}
                value={bottomAddBannerText}
              />
            </div>
            <div className="">
              <h5 className="text-xl font-medium pb-2">Side Banner Text:</h5>
              <textarea
                cols="50"
                rows="5"
                className="outline-none p-3 border border-gray-400 rounded-lg"
                defaultValue={cureentText[0]?.sideBannerText}
                onChange={(e) => setSideBannerText(e.target.value)}
                value={sideBannerText}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleTextUpdate}
            className="bg-tealBlue text-white py-2 px-4 mr-4 mt-3 rounded border justify-center hover:bg-lightBlue hover:text-black border-borderMuted flex focus:outline-none transition-all duration-500"
          >
            {processing ? <SmallLoader /> : null}
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default GenerateLinks;
