import { useEffect } from "react";
import { useNavigate } from "react-router";
import { checkLogin } from "../api/auth";

const PublicContainer = ({ children, roles }) => {
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async () => {
    try {
      const { data } = await checkLogin();
      if (data.user && data.user.role === "user") {
        navigate("/");
      }
      if (data.user && data.user.role === "admin") {
        navigate("/admin");
      }
    } catch (error) {
        // DO NOTHING
    }
  };

  return children;
};

export default PublicContainer;
