import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { assignQRToUser, getLink } from "../api/qrcode";
import { checkLogin } from "../api/auth";

const NotFound = () => {
  const params = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLink = async (link) => {
      try {
        const { data } = await getLink(link);
        if (data.barcode) {
          const { barcode } = data;
          if (barcode?.storedInfo) {
            if (
              barcode?.storedInfo.infoType === "phoneNumber" &&
              barcode?.storedInfo.link
            ) {
              // window.open = `tel:${barcode?.storedInfo.link}`;
              var phoneNumber = `${barcode?.storedInfo.link}`;
              var phoneLink = document.createElement("a");
              phoneLink.href = "tel:" + encodeURIComponent(phoneNumber);
              phoneLink.click();
            } else if (
              ["link", "pdf", "image"].includes(barcode?.storedInfo.infoType) &&
              barcode?.storedInfo.link
            ) {
              let url = barcode?.storedInfo.link;
              if (!(url.includes("http://") || url.includes("https://"))) {
                url = "https://" + url;
              }
              window.location.href = url;
            } else if (
              barcode?.storedInfo.infoType === "memories"
            ) {
              navigate(`/info/${data.barcode._id}`);
            } else if (
              barcode?.approved &&
              barcode.isActive &&
              !barcode.user &&
              !barcode.restricted
            ) {
              assignUser(barcode);
            } else {
              window.location.href = "https://rainbowrebels.store";
            }
          } else if (
            barcode?.approved &&
            barcode.isActive &&
            !barcode.user &&
            !barcode.restricted
          ) {
            assignUser(barcode);
          } else {
            window.location.href = "https://rainbowrebels.store";
          }
        }
        return null;
      } catch (error) {
        console.log("Error while getting link");
      }
    };
    fetchLink(params.pathname.replace("/", ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const assignUser = async (barcode) => {
    try {
      const { data } = await checkLogin();
      if (data.user.role !== "admin" && data.user.emailVerified) {
        await assignQRToUser({
          link: barcode?._id,
          user: data.user._id,
        });
        navigate("/");
        return true;
      } else if (data.user.role !== "admin" && (!data.user.emailVerified)) {
        window.location.href = "https://rainbowrebels.store";
      }
    } catch (error) {
      navigate("/register", { state: { barcode } });
    }
  };

  return null;
};

export default NotFound;
