import { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { IoImage } from "react-icons/io5";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileUpload } from "react-icons/md";
import "react-phone-number-input/style.css";
import toast from "react-hot-toast";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import Header from "./header";
import {
  deleteStoredImage,
  getQRCodes,
  updateUserQRCodes,
} from "../api/qrcode";
import { Link, useParams } from "react-router-dom";
import apiClient from "../api";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbGrave2 } from "react-icons/tb";
import Memories from "./componets/Memories";
import axios from "axios";

const schema = (activeTab) => {
  switch (activeTab) {
    case 1:
      return yup.object({
        link: yup
          .string()
          .matches(
            /^(?:(?:https?:\/\/)?[^\s"']+\.([^.\s"']{2,}|.{2,5})|[^.\s"']{2,}\.[^.\s"']{2,})?$/,
            "Invalid URL format"
          )
          .nullable(),
        name: yup.string("Name must be string"),
      });
    case 2:
      return yup.object({
        contactNum: yup
          .string()
          .test("is-valid", "Invalid phone number", (value) => {
            if (!value) return true;
            return isValidPhoneNumber(value);
          })
          .nullable(),
        name: yup.string("Name must be string"),
      });
    case 3:
      return yup.object().shape({
        title: yup.string(),
        freeText: yup.string(),
        videoUrl: yup
          .string()
          .matches(
            /^(?:(?:https?:\/\/)?[^\s"']+\.([^.\s"']{2,}|.{2,5})|[^.\s"']{2,}\.[^.\s"']{2,})?$/,
            "Invalid URL format"
          )
          .nullable(),
        description: yup.string().max(4000, "Description is too long"),
        imageText: yup
          .string()
          .max(4000, "Image gallery description is too long"),
        pdfTitle: yup.string(),
      });
    case 4:
      return yup.object({
        name: yup.string(),
      });
    case 5:
      return yup.object({
        name: yup.string(),
      });
    default:
      return yup.object();
  }
};

const EditQr = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [barcodeData, setBarcodeData] = useState(null);

  // tab
  const changeTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  // form
  const methods = useForm({
    resolver: yupResolver(schema(activeTab)),
    defaultValues: {
      link: null,
      name: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const uploadToS3 = async (files) => {
    try {
      const { data } = await apiClient().post(`/barcode/memories/${id}`, {
        files: files.map((file) => ({ name: file.name, type: file.type })),
        folderPath: "memories",
      });
  
      const uploadPromises = data.data.map((file, index) =>
        axios.put(file.presignedUrl, files[index], {
          headers: {
            "Content-Type": files[index].type,
          },
        })
      );
  
      // Wait for all uploads to finish
      await Promise.all(uploadPromises);
  
      return {
        publicUrls: data.data.map((ele) => ele.publicUrl),
      };
    } catch (error) {
      console.error("Error uploading to S3:", error);
      toast.error(error?.response?.data?.message || "Upload failed");
      throw error;  // Re-throw the error to handle it in the onSubmit
    }
  };
  

  const onSubmit = async (data) => {
    setProcessing(true);
    let obj = {
      name: data.name,
      type: tabMapper[activeTab],
    };
  
    try {
      if (tabMapper[activeTab] === "memories") {
        obj["memories"] = {
          title: data.title,
          freeText: data.freeText,
          videoUrl: data.videoUrl,
          videoDescription: data.videoDescription,
          imageText: data.imageText,
          pdfTitle: data.pdfTitle,
        };
  
        const uploadPromises = [];
  
        // Videos
        if (data.videos?.length) {
          const newVideos = data.videos.filter((ele) => ele instanceof File);
          const preVideos = data.videos.filter((ele) => typeof ele === "string");
          if (newVideos.length > 0) {
            uploadPromises.push(uploadToS3(newVideos).then(result => ({type: 'videos', result})));
          }
          obj["memories"]["videos"] = preVideos;
        } else {
          obj["memories"]["videos"] = [];
        }
  
        // Images
        if (data.images?.length) {
          const newImages = data.images.filter((ele) => ele instanceof File);
          const preImages = data.images.filter((ele) => typeof ele === "string");
          if (newImages.length > 0) {
            uploadPromises.push(uploadToS3(newImages).then(result => ({type: 'images', result})));
          }
          obj["memories"]["images"] = preImages;
        } else {
          obj["memories"]["images"] = [];
        }
  
        // PDFs
        if (data.pdfs?.length) {
          const newPdfs = data.pdfs.filter((ele) => ele instanceof File);
          const prePdfs = data.pdfs.filter((ele) => typeof ele === "string");
          if (newPdfs.length > 0) {
            uploadPromises.push(uploadToS3(newPdfs).then(result => ({type: 'pdfs', result})));
          }
          obj["memories"]["pdfs"] = prePdfs;
        } else {
          obj["memories"]["pdfs"] = [];
        }
  
        const uploadResults = await Promise.all(uploadPromises);
  
        uploadResults.forEach(({type, result}) => {
          obj["memories"][type] = [...obj["memories"][type], ...result.publicUrls];
        });
  
        console.log("memories obj=> ", obj);
      } else if (tabMapper[activeTab] === "link") {
        obj["link"] = data.link;
        console.log("link => ", data);
      } else if (tabMapper[activeTab] === "phoneNumber") {
        obj["phoneNumber"] = data.contactNum;
        console.log("phoneNumber => ", data);
      }
  
      // Now that all uploads are complete, update the QR code
      await updateUserQRCodes(id, obj);
      getBarcodeData();
      toast.success("QR code updated successfully");
    } catch (error) {
      console.log("Error while updating qr code ", error);
      toast.error(
        error?.response?.data?.message || "Error while updating qr code"
      );
    } finally {
      setProcessing(false);
    }
  };

  const tabMapper = {
    1: "link",
    2: "phoneNumber",
    3: "memories",
    4: "pdf",
    5: "image",
  };

  const [image, setImage] = useState(null);
  const getBarcodeData = async () => {
    try {
      const { data } = await getQRCodes(id);
      setBarcodeData(data.barcode);
      setFilePreview(null);
      setValue(
        "contactNum",
        data?.barcode?.storedInfo?.infoType === "phoneNumber"
          ? data?.barcode?.storedInfo?.link || ""
          : ""
      );
      setValue(
        "link",
        data?.barcode?.storedInfo?.infoType === "link"
          ? data?.barcode?.storedInfo?.link
          : ""
      );
      setValue("name", data?.barcode?.name ? data.barcode.name : "");

      setImage(
        data?.barcode?.storedInfo?.infoType === "image"
          ? data?.barcode?.storedInfo?.link
          : null
      );
      if (data?.barcode?.storedInfo?.infoType === "pdf") {
        // # Split the URL by "/"
        const parts = data?.barcode?.storedInfo?.link.split("/");
        // # The last part after the last "/" is the file name
        const file_name = parts[parts.length - 1];
        setFilePreview(
          data?.barcode?.storedInfo?.infoType === "pdf" && file_name
            ? { name: file_name, url: data?.barcode?.storedInfo?.link }
            : null
        );
      }
      Object.keys(tabMapper).forEach((key) => {
        if (tabMapper[key] === data?.barcode?.storedInfo?.infoType) {
          console.log("changes", key);
          changeTab(parseInt(key));
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error while getting barcode data"
      );
    }
  };
  useEffect(() => {
    if (id) {
      getBarcodeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // file upload
  const [progress, setProgress] = useState(0);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 20 * 1024 * 1024, // 5mb
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (acceptedFiles, notAcceptedFile) => {
      if (notAcceptedFile.length > 0) {
        toast.error(notAcceptedFile[0].errors[0].message);
        return;
      }
      if (
        tabMapper[activeTab] === "image" &&
        !acceptedFiles[0]?.type.startsWith("image")
      ) {
        toast.error("Only image files are allowed");
        return;
      }
      if (
        tabMapper[activeTab] === "pdf" &&
        !acceptedFiles[0]?.type === "application/pdf"
      ) {
        toast.error("Only pdf files are allowed");
        return;
      }

      try {
        await uploadPdfOrImage(acceptedFiles[0]);
        getBarcodeData();
        toast.success("QR code file updated successfully");
      } catch (error) {
        setProgress(0);
        console.log("Error while file updating qr code ", error);
        toast.error(
          error?.response?.data?.message || "Error while upload qr code"
        );
      }
    },
  });

  const uploadPdfOrImage = async (file) => {
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }
    formData.append("type", tabMapper[activeTab]);
    await apiClient().put(`/barcode/file/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setProgress((prevProgress) => prevProgress + percentage);
      },
    });
    setProgress(0);
  };

  // delete barcode stored image
  const handleDeleteStoredImage = async (id) => {
    try {
      const data = await deleteStoredImage(id);
      if (data?.status !== 200) throw data?.data;
      setFilePreview(null);
      setImage(null);
      toast.success(
        data?.data.message || "QR code stored file deleted successfully"
      );
      return true;
    } catch (error) {
      toast.error(error.message || "Error deleting QR code Image");
    }
  };

  const disableButtonCondition = () => {
    let condition = false;
    if (activeTab === 3) {
      if (
        !watch("title") &&
        !watch("freeText") &&
        !watch("videoUrl") &&
        !watch("imageText") &&
        !watch("videoDescription") &&
        !watch("pdfTitle") &&
        ((watch("images") && watch("images").length === 0) ||
          !watch("images")) &&
        ((watch("pdfs") && watch("pdfs").length === 0) || !watch("pdfs")) &&
        ((watch("videos") && watch("videos").length === 0) || !watch("videos"))
      ) {
        condition = true;
      }
    }
    return condition;
  };

  return (
    <>
      <Header />
      <div className="max-w-7xl mx-auto">
        <div className="my-8 mx-0 lg:my-16 lg:mx-16">
          <Link
            to="/"
            className="flex items-center border border-black p-2 rounded-md w-fit"
          >
            <IoMdArrowRoundBack className="me-2" />
            Back
          </Link>
          <h2 className="text-3xl font-medium mb-3 text-center">
            Edit QR Code:
          </h2>
          <div className="flex flex-col px-[20px]">
            <div className="flex justify-center my-2 flex-wrap">
              <button
                className={`${
                  activeTab === 1
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 my-1 border flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(1)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <FaLink />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>Url / Link</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 2
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(2)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <IoCallOutline />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>Phone Call</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 3
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(3)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <TbGrave2 />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>Create Page</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 4
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(4)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <FaRegFilePdf />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>PDF</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 5
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(5)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <IoImage />
                  </p>
                  <p className="text-[12px]">
                    <b>Image</b>
                  </p>
                </div>
              </button>
            </div>
            <div className="max-w-[800px] w-full mx-auto bg-white mt-4 sm:mt-6 rounded pt-3 pb-6 sm-pb-8 max-h-[800px] overflow-y-scroll">
              <div className="tab-content p-4 rounded-md max-w-[450px] w-full mx-auto">
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`tab-pane relative`}>
                      {/* only for mobile */}
                      {window.innerWidth < 768 &&
                        tabMapper[activeTab] === "memories" && (
                          <div className="top-[0%] z-50 mb-4">
                            <button
                              type="submit"
                              disabled={disableButtonCondition()}
                              className="max-w-[350px] mx-auto flex items-center justify-center focus:outline-none  disabled:bg-gray-400 text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-3 w-full transition duration-150 ease-in"
                            >
                              <span className="mr-2">Save</span>
                            </button>
                          </div>
                        )}

                      {tabMapper[activeTab] === "link" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <input
                              {...register("link")}
                              placeholder="Put your link here"
                              className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                errors?.link
                                  ? "border-red-500"
                                  : "border-gray-400"
                              } w-full py-3 focus:outline-none focus:border-blue-400`}
                            />
                          </div>
                          {errors?.link && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.link.message}
                            </span>
                          )}
                        </div>
                      )}

                      {tabMapper[activeTab] === "phoneNumber" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <PhoneInputWithCountry
                              id="contactNum"
                              name="contactNum"
                              defaultCountry="US"
                              control={control}
                              className={`py-3 w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                errors?.contactNum
                                  ? "border-red-500"
                                  : "border-gray-400"
                              }`}
                            />
                          </div>
                          {errors?.contactNum && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.contactNum.message}
                            </span>
                          )}
                        </div>
                      )}

                      {tabMapper[activeTab] === "memories" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <Memories data={barcodeData} />
                          </div>
                          {errors?.contactNum && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.contactNum.message}
                            </span>
                          )}
                        </div>
                      )}

                      {tabMapper[activeTab] === "pdf" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          {filePreview ? (
                            <div className="mb-3 rounded-md flex justify-between items-center relative">
                              <div className="border py-1 px-2 rounded-md flex-1 group text-ellipsis text-nowrap">
                                <span className="text-ellipsis text-nowrap">
                                  {filePreview.name}
                                </span>
                                <Link
                                  to={filePreview.url}
                                  target="_blank"
                                  className="hidden group-hover:block bg-black py-1 px-2 rounded-md absolute top-[-100%] text-base text-white z-10"
                                >
                                  {" "}
                                  {filePreview.url}
                                </Link>
                              </div>

                              <button
                                type="button"
                                onClick={(e) => handleDeleteStoredImage(id)}
                                className="text-2xl font-medium w-[20px] h-[20px] rounded-full bg-white flex items-center justify-center mx-2"
                              >
                                <RiDeleteBinLine />
                              </button>
                            </div>
                          ) : null}
                          <div
                            {...getRootProps({
                              className:
                                "dropzone inline-block w-full text-sm text-tealBlue px-4 rounded-lg border border-blue-400 w-full py-3 bg-[#e8f0fe] cursor-pointer",
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="flex items-center justify-center">
                              <MdOutlineFileUpload className="mr-1 text-lg sm:text-2xl" />
                              <p className="inline-block text-sm sm:text-base font-medium text-center">
                                Upload PDF File
                              </p>
                            </div>
                            {/* Progress Bar */}
                            {progress > 0 && (
                              <div className="w-full h-1 bg-gray-200 mt-2">
                                <div
                                  className="h-full bg-green-500"
                                  style={{
                                    width: `${
                                      progress > 100 ? 100 : progress
                                    }%`,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {tabMapper[activeTab] === "image" && (
                        <>
                          {image && (
                            <>
                              <div className="mb-3 py-1 px-4 rounded-md flex justify-between items-center relative">
                                <img
                                  className="border py-1 px-2 rounded-md"
                                  src={image}
                                  alt="barcode"
                                />
                                <button
                                  type="button"
                                  onClick={(e) => handleDeleteStoredImage(id)}
                                  className="text-2xl mx-2 font-medium w-[20px] h-[20px] rounded-full bg-white flex items-center justify-center"
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </div>
                            </>
                          )}
                          <div className="flex flex-col mb-4 sm:mb-6">
                            <div
                              {...getRootProps({
                                className:
                                  "dropzone inline-block w-full text-sm text-tealBlue px-4 rounded-lg border border-blue-400 w-full py-3 bg-[#e8f0fe] cursor-pointer",
                              })}
                            >
                              <input {...getInputProps()} />
                              <div className="flex items-center justify-center">
                                <MdOutlineFileUpload className="mr-1 text-lg sm:text-2xl" />
                                <p className="inline-block text-sm sm:text-base font-medium text-center">
                                  Upload Image
                                </p>
                              </div>
                              {/* Progress Bar */}
                              {progress > 0 && (
                                <div className="w-full h-1 bg-gray-200 mt-2">
                                  <div
                                    className="h-full bg-green-500"
                                    style={{
                                      width: `${
                                        progress > 100 ? 100 : progress
                                      }%`,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {tabMapper[activeTab] !== "memories" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <input
                              {...register("name")}
                              className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                errors?.name
                                  ? "border-red-500"
                                  : "border-gray-400"
                              } w-full py-3 focus:outline-none focus:border-blue-400`}
                              placeholder="Name your QR (optional)"
                            />
                          </div>
                          {errors?.name && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                      )}

                      <div className="sticky top-[0%] z-50 mb-4">
                        <button
                          type="submit"
                          disabled={disableButtonCondition()}
                          className="max-w-[350px] mx-auto flex items-center justify-center focus:outline-none  disabled:bg-gray-400 text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-3 w-full transition duration-150 ease-in"
                        >
                          <span className="mr-2">Save</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>

        {/* Loading State */}
        {processing ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
            <div
              className="h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EditQr;
