import apiClient from "./index";

// Create a function that makes query params from object
const makeQueryString = (obj) => {
  const queryString = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
  return `?${queryString}`;
};

export const listQrCodes = (queryParams) =>
  apiClient().get(`/barcode${queryParams ? makeQueryString(queryParams) : ""}`);

export const generateLinks = (data) => apiClient().post("/barcode", data);

export const getQrCode = (link) => apiClient().get(`/barcode/${link}`);

export const approveQrCode = (link) =>
  apiClient().put(`/barcode/${link}/approveToggle`);

export const enableQrCode = (id) => apiClient().get(`/barcode/${id}/enable`);

export const disableQrCode = (id) => apiClient().get(`/barcode/${id}/disable`);

export const getLink = (link) => apiClient().get(`/barcode/${link}`);

export const assignQRToUser = (data) =>
  apiClient().post("/barcode/assign/user", data);

export const getUserQRCodes = (userId) =>
  apiClient().get(`/barcode/user/${userId}`);

export const updateUserQRCodes = (id, data) =>
  apiClient().put(`/barcode/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getQRCodes = (id) => apiClient().get(`/barcode/qrcode/${id}`);

// Scanned QR
export const scannedQRCode = (id) => apiClient().get(`/barcode/scanned/${id}`);

export const deleteQRCode = (id) => apiClient().delete(`/barcode/${id}`);

export const deleteQRCodeImage = (id) =>
  apiClient().delete(`/barcode/${id}/imageLink`);

export const deleteStoredImage = (id) =>
  apiClient().delete(`/barcode/${id}/storedInfo`);

export const resetQRCodeAll = (id) => apiClient().put(`/barcode/reset/${id}`);
