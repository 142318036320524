import React from "react";

const LongBanner = ({ content }) => {
  return (
    <div className="w-full p-3 long-banner">
      <p className="sm:text-lg text-base">{content}</p>
    </div>
  );
};

export default LongBanner;
