import axios from 'axios';

const apiClient = () => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: Number.MAX_SAFE_INTEGER,
    headers,
    withCredentials: true
  });

  return instance;
};

export default apiClient;