import React from "react";
import { IoMdClose } from "react-icons/io";

const Modal = ({
  handleClick,
  submitName,
  closeName,
  removeButtons,
  children,
  title = "Upload QR code images",
}) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-[600px] w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5">
              <h3 className="text-3xl font-semibold tex-center mx-auto">
                {title}
              </h3>
              <button className="text-2xl" onClick={handleClick}>
                <IoMdClose />
              </button>
            </div>
            {/*body*/}
            {children}
            {/*footer*/}
            {!removeButtons && (
              <div className="flex items-center justify-center p-6">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClick}
                >
                  {closeName}
                </button>
                <button
                  type="button"
                  onClick={handleClick}
                  className="bg-tealBlue text-white py-2 px-4 rounded border flex-col justify-center hover:bg-lightBlue hover:text-black border-borderMuted flex focus:outline-none transition-all duration-500"
                >
                  {submitName}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Modal;
