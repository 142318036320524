import { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import Modal from "./Modal";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import PhoneInputWithCountry, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { resetQRCodeAll, updateUserQRCodes } from "../../api/qrcode";
import SmallLoader from "./smallLoader";

const schema = (activeTab) => {
  switch (activeTab) {
    case 1:
      return yup.object({
        link: yup
          .string()
          .matches(/^(http|https):\/\/[^ "]+$/, { excludeEmptyString: true, message: "Invalid URL format" },).nullable(),
        name: yup.string("Name must be string"),
      });
    case 2:
      return yup.object({
        contactNum: yup
          .string()
          .test("is-valid", "Invalid phone number", (value) => {
            if (!value) return true;
            return isValidPhoneNumber(value);
          }),
        name: yup.string("Name must be string"),
      });
    default:
      return yup.object()
  }
}

const QREditModal = ({ setShowModal, refreshData, barCode }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [resetModel, setResetModel] = useState(false);
  const [qrId, setQrId] = useState("");
  const [processing, setProcessing] = useState(false);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const tabMapper = {
    0: "link",
    1: "phoneNumber",
  };

  const methods = useForm({
    resolver: yupResolver(schema(activeTab)),
    defaultValues: {
      link: null,
      name: "",
      contactNum: null,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;
  useEffect(() => {
    if (barCode.storedInfo) {
      setValue("name", barCode.name);
      if (barCode.storedInfo.infoType === "link") {
        setValue("link", barCode.storedInfo.link);
        setActiveTab(0);
      } else if (barCode.storedInfo.infoType === "phoneNumber") {
        setValue("contactNum", barCode.storedInfo.link);
        setActiveTab(1);
      }
    }
  }, [barCode, setValue]);

  const onSubmit = async (data) => {
    let obj = {
      name: data.name,
      type: tabMapper[activeTab],
    };
    if (tabMapper[activeTab] === "link") {
      obj["link"] = data.link;
    } else if (tabMapper[activeTab] === "phoneNumber") {
      obj["phoneNumber"] = data.contactNum;
    }
    try {
      await updateUserQRCodes(barCode?._id, obj);
      toast.success("QR code updated successfully");
    } catch (error) {
      console.log("Error while updating qr code ", error);
      toast.error(
        error?.response?.data?.message || "Error while updating qr code"
      );
    } finally {
      refreshData();
      setShowModal(false);
    }
  };

  // reset barcode all details
  const handleDeleteQrCodeAll = async (id) => {
    setProcessing(true);
    try {
      const data = await resetQRCodeAll(id);
      if (data?.status !== 200) throw data?.data;
      refreshData();
      toast.success(data?.data.message || "QR code deleted successfully");
      return true;
    } catch (error) {
      toast.error(error.message || "Error deleting qr code");
    } finally {
      setProcessing(false);
      setShowModal(false);
    }
  };

  const toggleResetModal = () => {
    setResetModel(!resetModel);
  };

  return (
    <>
      {resetModel && (
        <div className="fixed top-0 h-screen w-screen bg-black bg-opacity-60 flex items-center justify-center z-[999]">
          <div className="bg-white max-w-xl rounded-md">
            <div className="relative p-3 flex items-center justify-cente">
              <div className="p-3">
                <p>Are you sure you want to reset this QR code?</p>
              </div>
            </div>
            <div className="p-3 flex justify-end gap-x-3">
              <button
                className="modal-close text-sm text-white bg-blue-500 hover:text-gray-400 hover:bg-white border rounded-md px-4 py-2"
                onClick={() => {
                  handleDeleteQrCodeAll(qrId);
                  toggleResetModal();
                  setQrId(null);
                }}
              >
                Yes
              </button>
              <button
                className="modal-close text-sm hover:text-white hover:bg-blue-500 text-gray-400 border rounded-md px-4 py-2"
                onClick={() => {
                  toggleResetModal();
                  setQrId(null);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        handleClick={() => setShowModal(false)}
        closeName="Cancel"
        submitName="Save"
        removeButtons
      >
        <div className="flex flex-col px-[20px]">
          {barCode?.user && (
            <div className="flex justify-center flex-col my-2">
              <p className="text-center text-sm sm:text-base">
                <b>Name:</b> {barCode?.user?.firstName} {barCode?.user?.lastName}
              </p>
              <br />
              <p className="text-center text-sm sm:text-base">
                <b>Email:</b> {barCode?.user?.email}
              </p>
            </div>
          )}
          {barCode?.storedInfo?.link && (
            <div className="flex justify-center flex-col my-2">
              <p className="text-center text-sm sm:text-base">
                <b>{barCode?.storedInfo?.infoType}</b> {barCode?.storedInfo?.infoType === "phoneNumber" ? barCode?.storedInfo?.link : (<a href={barCode?.storedInfo?.link}>{barCode?.storedInfo?.link}</a>)}
              </p>
            </div>
          )}
          <div className="flex justify-center my-2 flex-wrap">
            <button
              className={`${
                activeTab === 0
                  ? "bg-tealBlue text-white"
                  : "bg-lightBlue text-white-700"
              } py-2 px-4 rounded mx-2 sm:mx-4 my-1 border flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
              onClick={() => changeTab(0)}
            >
              <div className="flex ">
                <p className="mx-1">
                  <FaLink />{" "}
                </p>
                <p className="text-[12px]">
                  <b>Url / Link</b>{" "}
                </p>
              </div>
            </button>
            <button
              className={`${
                activeTab === 1
                  ? "bg-tealBlue text-white"
                  : "bg-lightBlue text-white-700"
              } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
              onClick={() => changeTab(1)}
            >
              <div className="flex ">
                <p className="mx-1">
                  <IoCallOutline />{" "}
                </p>
                <p className="text-[12px]">
                  <b>Phone Call</b>{" "}
                </p>
              </div>
            </button>
          </div>
          <div className="max-w-[800px] w-full mx-auto bg-white mt-4 sm:mt-6 rounded pt-3 pb-6 sm-pb-8">
            <div className="tab-content p-4 rounded-md max-w-[450px] w-full mx-auto">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={`tab-pane`}>
                    {tabMapper[activeTab] === "link" && (
                      <div className="flex flex-col mb-4 sm:mb-6">
                        <div className="relative">
                          <input
                            {...register("link")}
                            placeholder="Put your link here"
                            className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                              errors?.link ? "border-red-500" : "border-gray-400"
                            } w-full py-3 focus:outline-none focus:border-blue-400`}
                          />
                        </div>
                        {errors?.link && (
                          <span className="text-red-500 text-xs mt-1">
                            {errors.link.message}
                          </span>
                        )}
                      </div>
                    )}
  
                    {tabMapper[activeTab] === "phoneNumber" && (
                      <div className="flex flex-col mb-4 sm:mb-6">
                        <div className="relative">
                          <Controller
                            name="contactNum"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <PhoneInputWithCountry
                                value={value}
                                onChange={onChange}
                                id="contactNum"
                                defaultCountry="US"
                                className={`py-3 w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                  errors?.contactNum
                                    ? "border-red-500"
                                    : "border-gray-400"
                                }`}
                              />
                            )}
                          />
                        </div>
                        {errors?.contactNum && (
                          <span className="text-red-500 text-xs mt-1">
                            {errors.contactNum.message}
                          </span>
                        )}
                      </div>
                    )}
  
                    <div className="flex flex-col mb-4 sm:mb-6">
                      <div className="relative">
                        <input
                          {...register("name")}
                          className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                            errors?.name ? "border-red-500" : "border-gray-400"
                          } w-full py-3 focus:outline-none focus:border-blue-400`}
                          placeholder="Name your QR (optional)"
                        />
                      </div>
                      {errors?.name && (
                        <span className="text-red-500 text-xs mt-1">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-around gap-3 px-11">
                    <div className="w-full">
                      <button
                        type="submit"
                        className="justify-center w-full bg-[#0082B2] hover:bg-[#006d95] mb-2 text-white px-3 py-1 mr-4 rounded border border-borderMuted flex focus:outline-none transition-all duration-500"
                      >
                        <span>Save</span>
                      </button>
                    </div>
                    <div className="w-full">
                      <button
                        onClick={() => {
                          toggleResetModal();
                          setQrId(barCode?._id);
                        }}
                        type="button"
                        className="justify-center w-full bg-red-500 hover:bg-red-600 mb-2 text-white px-3 py-1 mr-4 rounded border border-borderMuted flex focus:outline-none transition-all duration-500"
                      >
                        {processing ? <SmallLoader /> : null}
                        RESET
                      </button>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QREditModal;
