import EditQr from "../pages/editQr";
import Login from "../pages/login";
import GenerateLinks from "../pages/generateLinks";
import Register from "../pages/register";
import BarcodeLists from "../pages/barcodeLists";
import EditProfile from "../pages/editProfile";
import ForgotPassword from "../pages/forgot-password";
import InfoPage from "../pages/infoPage";

const Admin = ["admin"];
const User = ["user"];
const All = ["admin", "user"];

const routes = [
  {
    path: "/",
    Component: BarcodeLists,
    role: User,
    secure: true,
  },
  {
    path: "/edit/:id",
    Component: EditQr,
    role: User,
    secure: true,
  },
  {
    path: "/profile",
    Component: EditProfile,
    role: All,
    secure: true,
  },
  {
    path: "/info/:id",
    Component: InfoPage,
    role: All,
    secure: false,
  },
  {
    path: "/login",
    Component: Login,
    secure: false,
  },
  {
    path: "/reset",
    Component: ForgotPassword,
    secure: false,
  },
  {
    path: "/register",
    Component: Register,
    secure: false,
  },
  {
    path: "/admin",
    Component: GenerateLinks,
    role: Admin,
    secure: true,
  },
];

export default routes;
