import React, { useState } from 'react';


const Tooltip = ({ text, children }) => {

    const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
    <span
      className="text-gray-600 cursor-pointer"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
    </span>
    {showTooltip && (
      <div className="absolute z-10 bg-gray-800 text-white py-1 px-2 rounded-md shadow-md">
        {text}
      </div>
    )}
  </div>
  )
}

export default Tooltip
