import React from "react";

const ShortBanner = ({ content }) => {
  return (
    <div className="border border-gray-400 rounded-xl bg-white w-full p-3 h-full min-h-[150px] mg:min-h-[300px] short-banner">
      <p className="sm:text-lg text-base">{content}</p>
    </div>
  );
};

export default ShortBanner;
