import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImUpload } from "react-icons/im";
import { AiOutlineClose, AiOutlineCloudUpload } from "react-icons/ai";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";

export default function Memories({ data }) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [acceptedVideoFiles, setAcceptedVideoFiles] = useState([]);
  const [acceptedImageFiles, setAcceptedImageFiles] = useState([]);
  const [acceptedPdfFiles, setAcceptedPdfFiles] = useState([]);
  const maxChars = 4000;

  useEffect(() => {
    let keys = [
      "title",
      "freeText",
      "videoUrl",
      "videoDescription",
      "imageText",
      "pdfTitle",
      "videos",
      "images",
      "pdfs",
    ];

    keys.forEach((key) => {
      setValue(key, data?.storedInfo?.memories?.[key]);
    });

    setAcceptedVideoFiles(data?.storedInfo?.memories?.videos || []);
    setAcceptedImageFiles(data?.storedInfo?.memories?.images || []);
    setAcceptedPdfFiles(data?.storedInfo?.memories?.pdfs || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveFile = (file, fileType) => {
    const updateFiles = (prevFiles) =>
      prevFiles?.filter((f) =>
        typeof f === "string" ? f !== file : f.path !== file.path
      );
    switch (fileType) {
      case "video":
        setAcceptedVideoFiles(updateFiles);
        setValue("videos", updateFiles(watch("videos")));
        break;
      case "image":
        setAcceptedImageFiles(updateFiles);
        setValue("images", updateFiles(watch("images")));
        break;
      case "pdf":
        setAcceptedPdfFiles(updateFiles);
        setValue("pdfs", updateFiles(watch("pdfs")));
        break;
      default:
        break;
    }
  };

  const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } =
    useDropzone({
      onDrop: async (acceptedFiles, notAcceptedFile) => {
        if (notAcceptedFile.length > 0) {
          toast.error(notAcceptedFile[0].errors[0].message);
          return;
        }
        let files = watch("videos") || [];
        setValue("videos", [...files, ...acceptedFiles]);
        setAcceptedVideoFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      },
      accept: { "video/*": [".mp4", ".mov", ".avi", ".wmv", ".flv", ".mkv"] },
      maxSize: 250 * 1024 * 1024, // 250mb
    });

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      onDrop: async (acceptedFiles, notAcceptedFile) => {
        if (notAcceptedFile.length > 0) {
          toast.error(notAcceptedFile[0].errors[0].message);
          return;
        }
        let imgs = watch("images") || [];
        setValue("images", [...imgs, ...acceptedFiles]);
        setAcceptedImageFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      },
      accept: {
        "image/*": [".jpeg", ".png", ".jpg", ".webp", ".svg"],
      },
      maxSize: 100 * 1024 * 1024, // 100mb
    });

  const { getRootProps: getPdfRootProps, getInputProps: getPdfInputProps } =
    useDropzone({
      onDrop: async (acceptedFiles, notAcceptedFile) => {
        if (notAcceptedFile.length > 0) {
          toast.error(notAcceptedFile[0].errors[0].message);
          return;
        }
        let pdfs = watch("pdfs") || [];
        setValue("pdfs", [...pdfs, ...acceptedFiles]);
        setAcceptedPdfFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      },
      accept: { "application/pdf": [".pdf"] },
      maxSize: 100 * 1024 * 1024, // 100mb
    });

  return (
    <section>
      <div>
        <h4>Title</h4>
        <input
          {...register("title")}
          className={`w-full text-sm mt-2 sm:text-base placeholder-gray-500 px-4 border-gray-400 rounded-lg border py-3 focus:outline-none focus:border-blue-400`}
          placeholder="E.g. In Loving Memory of John Doe"
          />
        {errors?.title && (
          <p className="text-red-500 text-xs mt-1">{errors.title.message}</p>
        )}
      </div>

      <div className="mt-4">
      <h4>Image</h4>
        <div className="flex items-center space-x-2 pt-2">
          <div
            className={`flex w-full justify-center items-center h-52 border-2 bg-blue-50 border-tealBlue border-dashed rounded-lg p-4`}
            {...getImageRootProps()}
          >
            <input {...getImageInputProps()} />
            <div className="text-center">
              <ImUpload className="mx-auto text-tealBlue text-6xl" />
              <button
                type="button"
                className="mt-4 bg-tealBlue text-white px-6 py-2 rounded-lg hover:bg-tealBlue"
              >
                Upload images
              </button>
              <p className="text-gray-600 text-sm font-bold mt-4">
                Maximum size: 100MB
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {acceptedImageFiles.map((file) => (
            <li
              key={typeof file === "string" ? file : file.path}
              className="flex justify-between items-center text-sm"
            >
              {typeof file === "string" ? file.split("/").pop() : file.path}
              <AiOutlineClose
                className="text-red-500 cursor-pointer"
                onClick={() => handleRemoveFile(file, "image")}
              />
            </li>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <h4>Image gallery/album description</h4>
        <div className="flex items-center space-x-2 pt-2">
          <div className="relative w-full">
            <textarea
              {...register("imageText")}
              className={`w-full h-32 text-sm sm:text-base placeholder-gray-500 px-4 border-gray-400 rounded-lg border py-3 focus:outline-none focus:border-blue-400`}
              placeholder="E.g. Pictures"
              maxLength={maxChars}
            />
            {errors?.imageText && (
              <p className="text-red-500 text-xs mt-1">
                {errors.imageText.message}
              </p>
            )}
          </div>
        </div>
        {errors?.imageText && (
          <p className="text-red-500 text-xs mt-1">
            {errors.imageText.message}
          </p>
        )}
      </div>

      <div className="mt-4">
        <h4>Free Text</h4>
        <textarea
          {...register("freeText")}
          className={`w-full mt-2 h-32 text-sm sm:text-base placeholder-gray-500 px-4 border-gray-400 rounded-lg border py-3 focus:outline-none focus:border-blue-400`}
          placeholder="Share memories or reflections about your loved one, honoring their legacy and life."
          maxLength={maxChars}
          />
        {errors?.freeText && (
          <p className="text-red-500 text-xs mt-1">{errors.freeText.message}</p>
        )}
      </div>

      <div className="mt-4">
        <h4>Video URL <span className="text-xs text-gray-500">(only to fill if you want to share a Youtube video or other video link)</span></h4>
        <div className="flex items-center space-x-2 pt-2">
          <input
            {...register("videoUrl")}
            className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 border-gray-400 rounded-lg border py-3 focus:outline-none focus:border-blue-400`}
            placeholder="https://www.youtube.com/watch..."
          />
        </div>
        {errors?.videoUrl && (
          <p className="text-red-500 text-xs mt-1">{errors.videoUrl.message}</p>
        )}
      </div>

      <div className="mt-4">
        <h4>You can also upload video(s) from your device</h4>
        <div className="flex items-center space-x-2 pt-2">
          <div
            className={`flex w-full justify-center items-center h-52 border-2 bg-blue-50 border-tealBlue border-dashed rounded-lg p-4`}
            {...getVideoRootProps()}
          >
            <input {...getVideoInputProps()}/>
            <div className="text-center">
              <AiOutlineCloudUpload className="mx-auto text-tealBlue text-6xl" />
              <button
                type="button"
                className="mt-4 bg-tealBlue text-white px-6 py-2 rounded-lg hover:bg-tealBlue"
              >
                Upload video(s)
              </button>
              <p className="text-gray-600 text-sm font-bold mt-4">
                Maximum size: 250MB
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {acceptedVideoFiles.map((file) => (
            <li
              key={typeof file === "string" ? file : file.path}
              className="flex justify-between items-center text-sm"
            >
              {typeof file === "string" ? file.split("/").pop() : file.path}
              <AiOutlineClose
                className="text-red-500 cursor-pointer"
                onClick={() => handleRemoveFile(file, "video")}
              />
            </li>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <h4>Video(s) Description</h4>
        <div className="flex items-center space-x-2 pt-2">
          <div className="relative w-full">
            <textarea
              {...register("videoDescription")}
              className={`w-full h-32 text-sm sm:text-base placeholder-gray-500 px-4 border-gray-400 rounded-lg border py-3 focus:outline-none focus:border-blue-400`}
              placeholder="E.g. Here is a video about..."
              maxLength={maxChars}
            />

            {errors?.videoDescription && (
              <p className="text-red-500 text-xs mt-1">
                {errors.videoDescription.message}
              </p>
            )}
          </div>
        </div>
        {errors?.description && (
          <p className="text-red-500 text-xs mt-1">
            {errors.description.message}
          </p>
        )}
      </div>
      
      <div className="mt-4">
        <h4>If you attach PDF file, you can describe content here</h4>
        <div className="flex items-center space-x-2 pt-2">
          <input
            {...register("pdfTitle")}
            className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 border-gray-400 rounded-lg border py-3 focus:outline-none focus:border-blue-400`}
            placeholder="E.g. Obituary"
          />
        </div>
        {errors?.pdfTitle && (
          <p className="text-red-500 text-xs mt-1">{errors.pdfTitle.message}</p>
        )}
      </div>
      <div className="mt-4">
        <h4>PDF File</h4>
        <div className="flex items-center space-x-2 pt-2">
          <div
            className={`flex w-full justify-center items-center h-52 border-2 bg-blue-50 border-dashed border-tealBlue rounded-lg p-4`}
            {...getPdfRootProps()}
          >
            <input {...getPdfInputProps()} />
            <div className="text-center">
              <ImUpload className="mx-auto text-tealBlue text-6xl" />
              <button
                type="button"
                className="mt-4 bg-tealBlue text-white px-6 py-2 rounded-lg hover:bg-tealBlue"
              >
                Upload PDF
              </button>
              <p className="text-gray-600 text-sm font-bold mt-4">
                Maximum size: 100MB
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {acceptedPdfFiles.map((file) => (
            <li
              key={typeof file === "string" ? file : file.path}
              className="flex justify-between items-center text-sm"
            >
              {typeof file === "string" ? file.split("/").pop() : file.path}
              <AiOutlineClose
                className="text-red-500 cursor-pointer"
                onClick={() => handleRemoveFile(file, "pdf")}
              />
            </li>
          ))}
        </div>
      </div>
    </section>
  );
}
