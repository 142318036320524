import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import MainLogo from "../assets/images/Main_red_highres.png";
import { logout } from "../api/auth";
import OnClickOutside from "../utils/onClickOutside";

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef("menu");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  OnClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };
  // const handleDropdown = () => {
  //   if (isOpen) {
  //     setIsOpen(false);
  //   }
  // };

  return (
    <>
      <div className="border-y border-gray-400 py-3 px-7">
        <div className="max-w-[1400px] w-full flex items-center justify-between mx-auto relative">
          <Link to="/" className="max-w-[250px] md:max-w-[300px] w-full">
            <img src={MainLogo} alt="Main Logo" className="" />
          </Link>
          <div className="" ref={wrapperRef}>
            <button onClick={toggleDropdown}>
              <CgProfile className="text-4xl cursor-pointer" />
            </button>
            {isOpen && (
              <div
                className="top-[74%] absolute right-[0%] mt-2 w-[150px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="py-1" role="none">
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Profile
                  </Link>
                  <Link
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {isOpen ? (
        <div
          className="absolute top-0 bottom-0 left-0 right-0"
          onClick={handleDropdown}
        ></div>
      ) : null} */}
    </>
  );
};

export default Header;
