import apiClient from "./index";

export const localRegister = (data) => apiClient().post("/user/register", data);

export const login = (data) => apiClient().post("/user/login", data);

export const googleAuth = () =>
  window.open(`${process.env.REACT_APP_API_URL}/auth/google`);

export const checkLogin = () => apiClient().get(`/auth/login/status`);

export const logout = () => apiClient().get(`/auth/logout`);

export const updateUser = (id, data) =>
  apiClient().put(`/user/update/${id}`, data);

export const forgotPassword = (data) =>
  apiClient().post("/user/reset-password", data);

export const verifyPassword = (data) =>
  apiClient().post("/user/reset-password/verify", data);

export const verifyOtp = (data) => apiClient().post("/user/otp-verify", data);

export const updateTexts = (data) => apiClient().put("/user/update-text", data);

export const getTexts = () => apiClient().get("/user/get-text");
