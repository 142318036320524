import { useEffect } from "react";

const OnClickOutside = (ref, onClickOutside) => {
  return (
    useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside();
          }
        }
        // Bind
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // dispose
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, onClickOutside])
  )
}

export default OnClickOutside;
