import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IoMdUnlock } from "react-icons/io";
import { MdAlternateEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { googleAuth, localRegister, verifyOtp } from "../api/auth";
import MainLogo from "../assets/images/Main_red_highres.png";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import SmallLoader from "./componets/smallLoader";

const schema = yup
  .object({
    firstName: yup
      .string("Firstname must be in string")
      .required("Firstname is required"),
    lastName: yup
      .string("Lastname must be string")
      .required("Lastname is required"),
    email: yup
      .string("Email must be string")
      .email("Please enter valid email")
      .lowercase()
      .required("Email is required"),
    password: yup
      .string("Please enter password")
      .min(6, "Password must be at least 6 characters long")
      .required("Password is required"),
  })
  .required();

const Register = () => {
  const [processing, setProcessing] = useState(false);

  const [emailSented, setEmailSented] = useState(false);
  const [otp, setOtp] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = async (payload) => {
    setProcessing(true);
    try {
      if (location?.state?.barcode?.link) {
        payload.link = location?.state?.barcode?._id;
      }
      await localRegister(payload);
      setEmailSented(true);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Error while login user ", error);
    } finally {
      setProcessing(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      localStorage.setItem("link", location?.state?.barcode?._id);
      googleAuth();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      if (otp?.length !== 6) throw new Error("Please enter valid otp");
      const email = getValues("email");
      const response = await verifyOtp({ otp, email });
      if (response?.status !== 200) throw response?.data;
      toast.success(response?.data?.message || "Otp verified successfully");
      navigate("/login");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Please enter valid otp");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center  justify-center bg-gray-100 px-4">
      <div className="flex flex-col bg-white shadow-md px-4 py-10 sm:px-6 md:px-8 lg:px-10   rounded-md w-full max-w-md">
        <img
          src={MainLogo}
          alt="Main Logo"
          className="max-w-[370px] md:max-w-[370px] w-full mx-auto mb-1"
        />
        <div className="otp-fields">
          {emailSented ? (
            <>
              <p>Please enter OTP sent to your email</p>
              <OtpInput
                value={otp}
                onChange={(data) => {
                  setOtp(data);
                }}
                numInputs={6}
                separator={<span style={{ width: "8px" }} />}
                inputType="number"
                isInputNum
                shouldAutoFocus
                inputStyle={{
                  margin: "20px 5px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "8px",
                  width: "40px",
                  height: "40px",
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  "&:focus": {
                    border: "none !important",
                    outline: "none",
                  },
                }}
                focusStyle={{
                  border: "none !important",
                  outline: "none",
                }}
                renderInput={(props) => <input {...props} />}
              />
              <button
                type="button"
                onClick={handleOtpSubmit}
                className="bg-tealBlue text-white py-2 px-4 rounded border flex-col justify-center hover:bg-lightBlue hover:text-black border-borderMuted flex focus:outline-none transition-all duration-500"
              >
                Submit
              </button>
            </>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <p className="mb-5 text-center font-medium">
                  Claim and manage the QR codes linked to items you purchased
                  from {" "} <Link to="https://rainbowrebels.store" className="text-tealBlue hover:opacity-80">RainbowRebels.store</Link>
                </p>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <FaUser className="text-[1.2rem] " />
                    </div>

                    <input
                      {...register("firstName")}
                      className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${
                        errors?.firstName ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Firstname"
                    />
                  </div>
                  {errors?.firstName && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.firstName.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <FaUser className="text-[1.2rem] " />
                    </div>
                    <input
                      {...register("lastName")}
                      className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${
                        errors?.lastName ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Lastname"
                    />
                  </div>
                  {errors?.lastName && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.lastName.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <MdAlternateEmail className="text-[1.2rem] " />
                    </div>
                    <input
                      {...register("email")}
                      className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border lowercase ${
                        errors?.email ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Email"
                    />
                  </div>
                  {errors?.email && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <span>
                        <IoMdUnlock className="text-[1.2rem]" />
                      </span>
                    </div>
                    <input
                      type="password"
                      {...register("password")}
                      className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${
                        errors?.password ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Password"
                    />
                  </div>
                  {errors?.password && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <div className="flex w-full">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-2 w-full transition duration-150 ease-in"
                  >
                    {processing ? <SmallLoader /> : null}
                    <span className="mr-2 ">Sign up</span>
                  </button>
                </div>
                <div className="flex  my-4">
                  <div className="flex w-full mt-1 justify-center flex-col">
                    <div className="w-full bg-[#ced1d7] h-[0.5px]"></div>
                  </div>
                  <div className="flex w-auto justify-center flex-col">
                    <div className="text-center   w-auto px-2  text-textGray my-y">
                      or
                    </div>
                  </div>
                  <div className="flex w-full mt-1 justify-center flex-col">
                    <div className="w-full bg-[#ced1d7] h-[0.5px]"></div>
                  </div>
                </div>
              </form>
              <div className="flex w-full">
                <button
                  onClick={handleGoogleLogin}
                  className="flex items-center border  border-[#747775]  justify-center focus:outline-none text-textBlack20 text-sm sm:text-base bg-white hover:bg-[#F2F2F2] rounded py-2 w-full transition duration-150 ease-in"
                >
                  <FcGoogle className="mx-2 text-2xl" />{" "}
                  <span className="mr-2 font-medium">
                    {" "}
                    Continue With Google
                  </span>
                </button>
              </div>
              <div className="mt-4">
                <p>
                  Already registered?{" "}
                  <Link
                    to="/login"
                    state={location?.state}
                    className="hover:text-tealBlue transition duration-150 ease-in"
                  >
                    Sign in here
                  </Link>
                </p>
              </div>
            </FormProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
