import { useState } from "react";
import Modal from "./Modal";
import { useDropzone } from "react-dropzone";
import apiClient from "../../api";
import toast from "react-hot-toast";

const QRUploadModal = ({ setShowModal, refreshData }) => {
  const [progress, setProgress] = useState(0);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (files) => {
      try {
        const uploadBatch = async (files) => {
          return await apiClient().post(
            "/barcode/upload",
            { files },
            {
              onUploadProgress: (progressEvent) => {
                // Track progress for each batch
                const percentage = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                setProgress(
                  (prevProgress) => prevProgress + percentage / files.length
                ); // Adjusted progress
              },
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        };

        const batchSize = 5;
        const totalBatches = Math.ceil(files.length / batchSize);
        const failedImages = [];

        for (let i = 0; i < totalBatches; i++) {
          const startIndex = i * batchSize;
          const endIndex = Math.min(startIndex + batchSize, files.length);
          const batch = files.slice(startIndex, endIndex);

          const { data } = await uploadBatch(batch);
          if (data.nonScanAbleImages && data.nonScanAbleImages.length > 0) {
            failedImages.push(...data.nonScanAbleImages);
          }
        }
        // Create CSV from failed images
        if (failedImages.length > 0) {
          const keys = Object.keys(failedImages[0]);

          const csvContent =
            "data:text/csv;charset=utf-8," +
            [
              keys.join(","),
              ...failedImages.map((row) =>
                keys.map((key) => row[key]).join(",")
              ),
            ].join("\n");
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            `failed-qr-${new Date().toISOString()}.csv`
          );
          document.body.appendChild(link);
          link.click();
        }
        toast.success("QR codes uploaded successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message || "Internal server error");
        console.error("Error uploading files:", error);
      } finally {
        setProgress(0);
        setShowModal(false);
        refreshData();
      }
    },
  });

  return (
    <Modal
      handleClick={() => setShowModal(false)}
      closeName="Cancel"
      submitName="Save"
    >
      <section className="p-5 mx-3 border-2 border-dashed rounded-lg h-[200px] max-w-[400px] w-full sm:mx-auto flex items-center justify-center">
        <div {...getRootProps({ className: "dropzone inline-block" })}>
          <input {...getInputProps()} />
          <div
            color="secondary"
            fontSize="large"
            style={{ marginLeft: "35px" }}
          />
          <p className="mx-auto inline-block text-lg font-medium max-w-[400px] w-full text-center px-16 py-16 cursor-pointer">
            Upload Documents
          </p>
        </div>
        {/* Progress Bar */}
        {progress > 0 && (
          <div className="w-full h-2 bg-gray-200 mt-2">
            <div
              className="h-full bg-green-500"
              style={{ width: `${progress > 100 ? 100 : progress}%` }}
            />
          </div>
        )}
      </section>
    </Modal>
  );
};

export default QRUploadModal;
