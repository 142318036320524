/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import Modal from "./Modal";
import { MdAlternateEmail } from "react-icons/md";

const schema = yup
  .object({
    email: yup
      .string("Email must be string")
      .email("Please enter valid email")
      .required("Email is required"),
  })
  .required();

const EmailFormModal = ({
  setShowModal,
  submitHandler = () => {},
  title = "Reset Password",
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      const response = await submitHandler(payload);
      // const response = await forgotPassword(payload);
      if (response?.status !== 200) {
        throw response;
      }
      toast.success(response.data.message || "Email sent successfully");
      setShowModal(false);
    } catch (error) {
      toast.error(error?.data?.message || "Something went wrong");
    }
  };
  return (
    <Modal
      handleClick={() => setShowModal(false)}
      removeButtons={true}
      title={title}
    >
      <div className="p-5">
        <p className="mb-5">
          Enter your email address and we will send you a link to reset
          your password.
       </p>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-6">
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <MdAlternateEmail className="text-[1.2rem]" />
                </div>
                <input
                  {...register("email")}
                  className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${
                    errors?.email ? "border-red-500" : "border-gray-400"
                  } w-full py-2 focus:outline-none focus:border-blue-400`}
                  placeholder="Email"
                />
              </div>
              {errors?.email && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="flex w-full">
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 ">Send Link</span>
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default EmailFormModal;
