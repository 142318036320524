import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdAlternateEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { verifyPassword } from "../api/auth";
import MainLogo from "../assets/images/Main_red_highres.png";
import toast from "react-hot-toast";

const schema = yup
  .object({
    password: yup
      .string("Password must be string")
      .min(8)
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .when("password", (password, field) =>
        password ? field.required().oneOf([yup.ref("password")]) : field
      ),
  })
  .required();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const response = await verifyPassword({
        password: payload?.password,
        token,
      });
      if (response?.status !== 200) {
        throw response;
      }
      toast.success(response?.data?.message || "password changed successfully");
      navigate("/login");
    } catch (error) {
      toast.error(error?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-10 rounded-md w-full max-w-md">
        <img
          src={MainLogo}
          alt="Main Logo"
          className="max-w-[250px] md:max-w-[300px] w-full mx-auto"
        />
        <div className="mt-5">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                    <MdAlternateEmail className="text-[1.2rem]" />
                  </div>
                  <input
                    {...register("password")}
                    className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${
                      errors?.password ? "border-red-500" : "border-gray-400"
                    } w-full py-2 focus:outline-none focus:border-blue-400`}
                    type="password"
                    placeholder="Password"
                  />
                </div>
                {errors?.password && (
                  <span className="text-red-500 text-xs mt-1">
                    {errors.password.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                    <MdAlternateEmail className="text-[1.2rem]" />
                  </div>
                  <input
                    {...register("confirmPassword")}
                    className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${
                      errors?.confirmPassword
                        ? "border-red-500"
                        : "border-gray-400"
                    } w-full py-2 focus:outline-none focus:border-blue-400`}
                    type="password"
                    placeholder="confirmPassword"
                  />
                </div>
                {errors?.confirmPassword && (
                  <span className="text-red-500 text-xs mt-1">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
              <div className="flex w-full">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-2 w-full transition duration-150 ease-in"
                >
                  <span className="mr-2 ">Submit</span>
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
