import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "./header";
import { updateUser } from "../api/auth";
import toast from "react-hot-toast";
import { checkLogin } from "../api/auth";

const schema = yup
  .object({
    firstName: yup.string("Firstname must be in string"),
    lastName: yup.string("Lastname must be string"),
    email: yup.string("Email must be string").email("Please enter valid email"),
    // password: yup
    //   .string("Please enter old password")
    //   .min(8, "Old password must be at least 8 characters long"),
    // newPassword: yup
    //   .string("Please enter new password")
    //   .min(8, "New password must be at least 8 characters long"),
    password: yup.string(),
    newPassword: yup.string(),
  })
  .required();

const EditProfile = () => {
  const [currentUser, setCurrentUser] = useState("");

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      newPassword: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    userDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userDetails = async () => {
    try {
      const { data } = await checkLogin();
      if (data?.user) {
        setCurrentUser(data.user);
        const { user } = data;
        setValue("firstName", user.firstName);
        setValue("lastName", user.lastName);
        setValue("email", user.email);
      }
    } catch (error) {
      console.log("Error while calling api ", error);
    }
  };

  const onSubmit = async (payload) => {
    try {
      const { firstName, lastName, email, password, newPassword } = payload;

      let handlePayload;
      if (!password && !newPassword) {
        handlePayload = {
          email,
          firstName,
          lastName,
        };
        await updateUser(currentUser?._id, handlePayload);
      } else {
        await updateUser(currentUser?._id, payload);
      }
      toast.success("User updated successfully");
    } catch (error) {
      toast.error("Error while updating user");
    }
  };
  return (
    <>
      <Header />
      <div className="py-8 px-10 lg:py-16 lg:px-16 max-w-[1400px] w-full mx-auto">
        <h2 className="text-3xl font-medium mb-5">Update Information</h2>
        <div className="flex flex-col bg-white shadow-md px-4 py-10 sm:px-6 md:px-8 lg:px-10 rounded-md w-full max-w-[600px] mx-auto md:mx-0">
          <div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-6 relative">
                  <label className="mr-2 mb-1 sm:mb-0 w-[90px]">
                    FirstName:
                  </label>
                  <div className="w-full max-w-[430px]">
                    <input
                      {...register("firstName")}
                      className={`w-full text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border ${
                        errors?.firstName ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Firstname"
                    />
                    {errors?.firstName && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-6 relative">
                  <label className="mr-2 mb-1 sm:mb-0 w-[90px]">
                    LastName:
                  </label>
                  <div className="w-full max-w-[430px]">
                    <input
                      {...register("lastName")}
                      className={`w-full text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border ${
                        errors?.lastName ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="LastName"
                    />
                    {errors?.lastName && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-6 relative">
                  <label className="mr-2 mb-1 sm:mb-0 w-[90px]">Email:</label>
                  <div className="w-full max-w-[430px]">
                    <input
                      {...register("email")}
                      className={`w-full text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border ${
                        errors?.email ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Email"
                    />
                    {errors?.email && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-6 relative">
                  <label className="mr-2 mb-1 sm:mb-0 w-[120px] md:w-[90px]">
                    Old Password:
                  </label>
                  <div className="w-full max-w-[430px]">
                    <input
                      type="password"
                      {...register("password")}
                      className={`w-full text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border ${
                        errors?.password ? "border-red-500" : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="Old Password"
                    />
                    {errors?.password && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-6 relative">
                  <label className="mr-2 mb-1 sm:mb-0 w-[120px] md:w-[90px]">
                    {" "}
                    New Password:
                  </label>
                  <div className="w-full max-w-[430px]">
                    <input
                      type="password"
                      {...register("newPassword")}
                      className={`w-full text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border ${
                        errors?.newPassword
                          ? "border-red-500"
                          : "border-gray-400"
                      } w-full py-2 focus:outline-none focus:border-blue-400`}
                      placeholder="New Password"
                    />
                    {errors?.newPassword && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.newPassword.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-2 transition duration-150 ease-in px-7"
                  >
                    <span className="mr-2 ">Update</span>
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
